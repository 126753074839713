import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Layout, Menu, Divider } from 'antd'
import { UserOutlined, MenuOutlined, WalletOutlined, LogoutOutlined } from '@ant-design/icons'
import { ReactComponent as Logo } from '../multimedia/logo.svg'
import styles from '../styles'

const { Header } = Layout

const Appbar = (props) => {

  const history = useHistory()
  const { isAuth, logout } = props
  return (
    <div>
      <Header style={styles.appbar} >
        <Logo style={styles.logo} onClick={() => { history.push('/') }} />
        {isAuth && <Menu mode="horizontal" items={[
          {
            label: '', key: 'SubMenu', icon: <MenuOutlined />,
            children: [
              { label: <Link to={"/profile"}>Mi Perfil </Link>, key: 1, icon: <UserOutlined /> },
              { label: <Link to={"/account"} >Mi cuenta PRESTO </Link>, key: 2, icon: <WalletOutlined /> },
              { label: `Cerrar sesión`, onClick: logout, key: 3, icon: <LogoutOutlined /> }
            ]
          }
        ]}
        />}
      </Header>
      <Divider style={styles.yellowLine} />
    </div >
  )

}

export default Appbar
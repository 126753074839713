import React from 'react'
import { connect } from 'react-redux'
import { updateConfiguration } from '../../actions'
import { getConfig } from '../../reducers'
import { Card, Button, Form, Input } from 'antd'
import Layout from '../../layout'
import styles from '../../styles'

class Configuration extends React.Component {

    storeConfiguration = (values) => {
        console.log("a ver el bug")
        this.props.updateConfiguration(values)
    }

    render() {
        const { config } = this.props
        return <Layout type={"backpage"}>
            <Card
                style={styles.generalCard}
                title={"Configuración de aplicación"}
            >

                <Form
                    name={"configuration"}
                    initialValues={config}
                    onFinish={this.storeConfiguration}
                >
                    <Form.Item label="Enlace de tienda" name="store" placeholder={"Enlace de tienda"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Teléfono principal" name="phone" placeholder={"Teléfono"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    config: getConfig(state)
})

const mapDispatchToProps = ({ updateConfiguration })

export default connect(mapStateToProps, mapDispatchToProps)(Configuration)
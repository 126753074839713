import React from 'react'
import {
    Card, Radio, Form, Button, Input, Typography
} from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import styles from '../styles'

class EmailSend extends React.Component {

    state = {
        radioSelected: 2
    }

    handleSelected = (e) => {
        this.setState({ radioSelected: e.target.value })
    }

    render() {
        const { setEmail, cancelEmail } = this.props
        return <Card
            style={styles.generalCard}
            bordered={false}
            title={<Typography.Title level={3} style={styles.whiteText} strong>ENVÍO DE PREVALÚO <CaretDownOutlined /></Typography.Title>}
            headStyle={styles.cover}
        >
            <Form
                name={"emailSend"}
                onFinish={setEmail}
                layout={"vertical"}
            >
                <Form.Item label="¿Cómo desea que se envíe su prevalúo?" name={"contactFrom"} >
                    <Radio.Group onChange={this.handleSelected} value={this.state.radioSelected} >
                        <Radio value="1" style={{ display: 'block', height: '30px', lineHeight: '30px' }}>Por Correo</Radio>
                        <Radio value="2" style={{ display: 'block', height: '30px', lineHeight: '30px' }}>Por WhatsApp</Radio>
                        <Radio value="0" style={{ display: 'block', height: '30px', lineHeight: '30px' }}>Ambas opciones</Radio>
                    </Radio.Group>
                </Form.Item>
                {Number(this.state.radioSelected) !== 2 &&
                    <Form.Item label={"Correo"} name={"email"} >
                        <Input type={"email"} />
                    </Form.Item>
                }

                <br />
                <Button type={"ghost"} onClick={cancelEmail} >Regresar</Button>
                <Button type={"primary"} htmlType="submit">Enviar solicitud</Button>
            </Form>
        </Card>
    }
}

export default EmailSend

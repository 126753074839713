import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Card, Button, Statistic, Row, Col, Input, Space } from 'antd'
import { getPrevalues } from '../../reducers'
import { fetchPrevalues } from '../../actions'
import Layout from '../../layout'
import { WhatsAppOutlined, WarningOutlined, CheckOutlined, AreaChartOutlined, SearchOutlined } from '@ant-design/icons'

const { Column } = Table

const tablePrevalues = (prevalues) => {
    return prevalues.map((prevalue) => {
        return {
            key: prevalue.id,
            name: prevalue.name,
            phone: prevalue.phone,
            amount: prevalue.amount,
            nCodPers: prevalue.nCodPers,
            status: prevalue.status,
            date: new Date(prevalue.created_at).toLocaleDateString('es')
        }
    })
}

class PrevaluesVehicleList extends React.Component {

    state = {
        searchText: '',
        searchedColumn: '',
    }

    componentDidMount() {
        this.props.fetchPrevalues(4)
    }

    getCountedPrevalues = () => this.props.prevalues.length
    getCompletedPrevalues = () => this.props.prevalues.filter((prevalue) => prevalue.status).length

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reiniciar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text => (text),
        sorter: (a, b) => !!a[dataIndex] ? a[dataIndex] > b[dataIndex] ? -1 : 1 : true
    })


    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    }

    render() {
        const { prevalues } = this.props
        return (
            <Layout type={"backpage"} >
                <Row justify={"space-between"}>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="Prevaluos Ingresados"
                                value={this.getCountedPrevalues()}
                                valueStyle={{ color: '#00a8c0' }}
                                prefix={<AreaChartOutlined />}
                            />
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="Prevaluos en proceso"
                                value={this.getCountedPrevalues() - this.getCompletedPrevalues()}
                                valueStyle={{ color: '#00a8c0' }}
                                prefix={<WarningOutlined />}
                            />
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="Prevaluos completados"
                                value={this.getCompletedPrevalues()}
                                valueStyle={{ color: '#00a8c0' }}
                                prefix={<CheckOutlined />}
                            />
                        </Card>
                    </Col>

                </Row>

                <br />

                <Table dataSource={tablePrevalues(prevalues)} >
                    <Column title={"Tipo"} {...this.getColumnSearchProps('nCodPers')} render={(record) => Number(record.nCodPers) !== 0 ? 'Cliente' : 'No cliente'} />
                    <Column title={"Nombre"} {...this.getColumnSearchProps('name')} render={(record) => record.name} />
                    <Column title={"Fecha de solicitud"} {...this.getColumnSearchProps('date')} render={(record) => record.date} />
                    <Column title={"Estado"} {...this.getColumnSearchProps('status')} render={record => record.status ? "Completado" : "Procesando"} />
                    <Column title={"Opciones"} render={(record) =>
                        <React.Fragment>
                            <Button href={`https://wa.me/+503${record.phone.replace('-', '')}?text=Estimado%20cliente:%20su%20solicitud%20ha%20sido%20enviada%20exitosamente,%20un%20ejecutivo%20se%20comunicará%20contigo%20para%20brindarle%20mayor%20información%20del%20proceso%20de%20su%20pre-solicitud.%20Presto%20donde%20tu%20carro%20vale%20más`}
                                icon={<WhatsAppOutlined />}
                                target={"_blank"}
                                rel="noreferrer"
                            />
                            <Link to={`/admin/prevalue/vehicle/${record.key}`}>
                                <Button >Ver</Button>
                            </Link>
                        </React.Fragment>
                    } />
                </Table>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    prevalues: getPrevalues(state)
})

const mapDispatchToProps = { fetchPrevalues }

export default connect(mapStateToProps, mapDispatchToProps)(PrevaluesVehicleList)
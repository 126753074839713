import React from 'react'
import { connect } from 'react-redux'
import { getAdmin, getAuth } from '../../reducers'
import { updateAdmin, updateAdminPassword } from '../../actions'
import { Button, Card, Form, Input } from 'antd'
import Layout from '../../layout'
import styles from '../../styles'

class Profile extends React.Component {

    render() {
        const { admin, updateAdmin, updateAdminPassword } = this.props

        return <Layout type={"backpage"}>
            <Card style={styles.generalCard}>
                <Form
                    onFinish={updateAdmin}
                    layout={"vertical"}
                    name={"updateAdmin"}
                    initialValues={admin}
                >
                    <Form.Item name={"email"} label={"Correo"} required>
                        <Input type={"email"} />
                    </Form.Item>
                    <Form.Item name={"name"} label={"Nombre"} required>
                        <Input />
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit">Guardar</Button>
                    </Form.Item>
                    <Form.Item name={"id"} ><Input type={"hidden"} /></Form.Item>
                </Form>


                <Form
                    onFinish={updateAdminPassword}
                    layout={"vertical"}
                    name={"updateAdminPassword"}
                >
                    <Form.Item name="password" label="Contraseña" rules={[{ required: true, message: 'Coloca tu nueva contraseña para actualizar', },]} hasFeedback>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item name="confirm" label="Repetir contraseña" dependencies={['password']} hasFeedback
                        rules={[{ required: true, message: 'Repite tu contraseña para continuar', },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Debo coincidir con la nueva contraseña'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item name={"id"} ><Input type={"hidden"} /></Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit">Guardar</Button>
                    </Form.Item>
                </Form>

            </Card>
        </Layout>
    }

}

const mapStateToProps = (state) => ({
    admin: getAdmin(state, getAuth(state))
})

const mapDispatchToProps = { updateAdmin, updateAdminPassword }

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
import React from 'react'
import { connect } from 'react-redux'
import { Card, Button, Space, Image, Descriptions } from 'antd'
import { getPrevalue } from '../../reducers'
import { APIpath, changeStatus } from '../../actions'
import Layout from '../../layout'
import styles from '../../styles'
import { WhatsAppOutlined } from '@ant-design/icons'

const PrevalueCar = (props) => {

    const { prevalue } = props
    let images = []
    for (var i = 0; i < prevalue.img; i++) {
        images.push(i + 1)
    }
    return (
        <Layout type={"backpage"} >
            <Card style={styles.generalCard}
                title={"Prevaluo " + prevalue.id + " de " + prevalue.name}
                extra={
                    <Button
                        href={`https://wa.me/+503${prevalue.phone.replace('-', '')}?text=Estimado%20cliente:%20su%20solicitud%20ha%20sido%20enviada%20exitosamente,%20un%20ejecutivo%20se%20comunicará%20contigo%20para%20brindarle%20mayor%20información%20del%20proceso%20de%20su%20pre-solicitud.%20Presto%20donde%20tu%20carro%20vale%20más`}
                        icon={<WhatsAppOutlined />}
                        target={"_blank"}
                        rel="noreferrer"
                    > Enviar prevalúo</Button>
                }
            >
                <Descriptions title={"Información del solicitante"} bordered column={1} >
                    <Descriptions.Item label={"Fecha de solicitud"} >
                        { new Date(prevalue.created_at).toLocaleDateString('es') }
                    </Descriptions.Item>
                    <Descriptions.Item label={"Tipo de cliente"}>
                        {Number(prevalue.nCodPers) !== 0 ? 'Cliente' : 'No cliente'}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Nombre"}>{prevalue.name}</Descriptions.Item>
                    <Descriptions.Item label={"Teléfono"}>{prevalue.phone}</Descriptions.Item>
                    <Descriptions.Item label={"Correo"}>{prevalue.email}</Descriptions.Item>
                    {(Number(prevalue.delivery) === 2 || Number(prevalue.nCodPers) === 0) &&
                        <React.Fragment>
                            <Descriptions.Item label={"Dirección"}>{prevalue.address}</Descriptions.Item>
                            <Descriptions.Item label={"Referencia"}>{prevalue.addressRef}</Descriptions.Item>
                            <Descriptions.Item label={"Departamento"}>{prevalue.departamento}</Descriptions.Item>
                            <Descriptions.Item label={"Municipio"}>{prevalue.municipio}</Descriptions.Item>
                        </React.Fragment>
                    }
                </Descriptions>
                <Descriptions bordered title={"Datos del prevalúo"} column={2}>
                    <Descriptions.Item label={"Tarjeta de circulación"}> {prevalue.tarjeta} </Descriptions.Item>
                    <Descriptions.Item label={"Año"}> {prevalue.anno} </Descriptions.Item>
                    <Descriptions.Item label={"Kilometraje"}> {prevalue.kilometraje} </Descriptions.Item>
                    <Descriptions.Item label={"N° de contacto"}> {prevalue.nContacto} </Descriptions.Item>
                    <Descriptions.Item label={"Modelo"}> {prevalue.model} </Descriptions.Item>
                    <Descriptions.Item label={"Tipo de petición"}> {Number(prevalue.delivery) !== 2 ? 'Agencia' : 'A domicilio'} </Descriptions.Item>
                    {Number(prevalue.delivery) !== 2 &&
                        <Descriptions.Item label={"Agencia"}>{prevalue.agencia}</Descriptions.Item>
                    }
                </Descriptions>
                <br />
                <Space wrap={true} align={"center"}>
                    {images.map(i =>
                        <Image key={i} src={`${APIpath}/prevalue/image/${prevalue.id}/${i}`} width={200} height={200} />
                    )}
                </Space>
                {prevalue.status ? undefined : <Button onClick={() => { props.changeStatus(prevalue.id) }} >Finalizar prevalúo</Button>}
            </Card>
        </Layout>
    )
}

const mapStateToProps = (state, ownProps) => ({
    prevalue: getPrevalue(state, ownProps.match.params.id)
})


const mapDispatchToProps = { changeStatus }

export default connect(mapStateToProps, mapDispatchToProps)(PrevalueCar)
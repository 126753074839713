import React from 'react'
import { connect } from 'react-redux'
import { getEntity } from '../reducers'
import { fetchEntity } from '../actions'
import {
    Card, Select, Form, Button, Upload, Typography, Input
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import background1 from '../multimedia/background1.svg'
import styles from '../styles'

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
}

class Jewelry extends React.Component {

    state = {
        showKilataje: false,
        plata: false
    }

    componentDidMount() {
        this.props.fetchEntity('prenda')
    }

    handleSubmit = (values) => {
        const { setJewelry } = this.props
        setJewelry(values)
    }

    handleKilatajeOption = (value) => {
        if (value === "ORO") {
            this.setState({ showKilataje: true, plata: false })
        } else {
            this.setState({ showKilataje: false, plata: true })
        }
    }



    render() {
        const { setUpdateState, prendas } = this.props
        return <Card
            style={styles.generalCard}
            bordered={false}
            cover={<img src={background1} alt={"Fondo"} />}
        >
            <Typography.Title align={"center"}>Prevaluós</Typography.Title>
            <Form
                name={"jewelry"}
                onFinish={this.handleSubmit}
                layout={"vertical"}
            >
                <Form.Item label={"Tipo de metal"} name={"metal"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={this.handleKilatajeOption} >
                        <Select.Option value={"ORO"} key={1} >Oro</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={"Tipo de prenda"} name={"prenda"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select>
                        {prendas.filter(prenda => {
                            switch (prenda.nValor) {
                                case 0:
                                case 10:
                                case 12:
                                case 13:
                                    return false
                                default:
                                    return true

                            }
                        }).map(prenda => {
                            return <Select.Option value={prenda.cNomCod} key={prenda.nValor}> {prenda.cNomCod}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                {this.state.showKilataje &&
                    <Form.Item label={"Kilataje"} name={"kilataje"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                        <Select>
                            <Select.Option value={"10"} key={1} >10</Select.Option>
                            <Select.Option value={"14"} key={2} >14</Select.Option>
                            <Select.Option value={"18"} key={3} >18</Select.Option>
                            <Select.Option value={"21"} key={4} >21</Select.Option>
                        </Select>
                    </Form.Item>
                }
                <Form.Item label={"Peso"} name={"peso"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input type="number" />
                </Form.Item>
                <Form.Item name="image1" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType={"picture"}
                        onRemove={() => { setUpdateState({ image1: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image1: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto frontal</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image2" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image2: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image2: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />} >Subir foto Lateral</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image3" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image3: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image3: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto trasera</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image4" valuePropName="fileList" getValueFromEvent={normFile} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image4: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image4: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto adicional</Button>
                    </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type={"primary"} htmlType="submit" block>Continuar</Button>
                </Form.Item>
            </Form>
        </Card>
    }
}

const mapStateToProps = (state) => ({
    prendas: getEntity(state, 'prenda')
})

const mapDispatchToProps = { fetchEntity }

export default connect(mapStateToProps, mapDispatchToProps)(Jewelry)
import { message } from 'antd'
import {
    CONFIGURATION,
    LOADING,
    REDIRECT,
    APIpath,
} from '../actions'
import axios from 'axios'

const setConfiguration = (config) => ({
    type: CONFIGURATION,
    config
})

export const setLoading = (status) => ({
    type: LOADING,
    status
})


export const setRedirect = (status) => ({
    type: REDIRECT,
    status
})

export const fetchConfiguration = () => (dispatch) => {

    axios.get(`${APIpath}/configuration`)
        .then(function (response) {
            dispatch(setConfiguration(response.data))
        })
        .catch(function (err) {
            message.error("Ha ocurrido un error")
            console.log(err)
        })
}

export const updateConfiguration = (values) => (dispatch) => {
    axios.post(`${APIpath}/configuration`, values)
        .then(function (response) {
            message.success("Configuración actualizada")
            dispatch(setConfiguration(response.data))
        })
        .catch(function (err) {
            message.error("Ha ocurrido un error")
            console.log(err)
        })
}
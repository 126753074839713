import React from 'react'
import { connect } from 'react-redux'
import { getUser, getAuth, getInvoices, getContract } from '../../reducers'
import { fetchInvoices } from '../../actions'
import {
    Card, Typography, Table, List
} from 'antd'
import CardHeaderInformation from '../../components/CardHeaderInformation'
import Layout from '../../layout'
import styles from '../../styles'

const { Column } = Table

const tableinvoices = (invoices) => {
    return invoices.map(invoice => {
        return {
            key: invoice.nNroTrans,
            agency: invoice.cnomage,
            cDescrip: invoice.cDescrip,
            tipoDocumento: invoice.TipoDocumento,
            NroDocumento: invoice.NroDocumento,
            fOper: invoice.FOper,
            Monto: invoice.Monto,
            Saldo: invoice.Saldo
        }
    })
}


class InvoiceList extends React.Component {

    componentDidMount() {
        const { contract } = this.props
        this.props.fetchInvoices(this.props.match.params.id, contract.tipo)
    }


    render() {
        const { auth, invoices, contract } = this.props
        return (
            <Layout type={"frontpage"} >
                <Card bordered={false}
                    title={<Typography.Title level={2} className={"text-center"} style={styles.whiteText}>MIS FACTURAS</Typography.Title>}
                    headStyle={styles.cover}
                    bodyStyle={styles.cardNoPadding}
                    style={styles.generalCard}
                >
                    <CardHeaderInformation
                        name={auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat}
                        dni={auth.cDNI}
                    />
                    <List
                        size="large"
                        dataSource={[
                            `N de contrato: ${contract.numerocontrato}`,
                            `Tipo de prestamo: ${contract.tipoproduc}`,
                            `Estado: ${contract.estado}`,
                            `Agencia: ${contract.agencia}`,
                            `Fecha de realización: ${contract.fecha}`,
                            `Fecha de vencimiento: ${new Date(contract.dFecVencimiento).toLocaleDateString('es', { timeZone: "UTC" })}`,
                            `Prestamo: $${contract.pres}`,
                            `Saldo: $${contract.sal}`,
                            `Renovación del mes: ${contract.TotalPagar}`
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                    <br />
                    <Table dataSource={tableinvoices(invoices)} >
                        <Column title={"Agencia"} render={(record) => record.agency} />
                        <Column title={"Tipo Operación"} render={(record) => record.cDescrip} />
                        <Column title={"Tipo Documento"} render={(record) => record.tipoDocumento} />
                        <Column title={"Nro. Serie"} render={(record) => record.NroDocumento} />
                        <Column title={"F Operación"} render={(record) => record.fOper} />
                        <Column title={"Monto"} render={(record) => `$${record.Monto}`} />
                        <Column title={"Saldo"} render={(record) => `$${record.Saldo}`} />
                    </Table>
                </Card>
            </Layout>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    auth: getUser(state, getAuth(state)),
    invoices: getInvoices(state),
    contract: getContract(state, ownProps.match.params.id)
})

const mapDispatchToProps = { fetchInvoices }

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList)
import React from 'react'
import { connect } from 'react-redux'
import { getUser } from '../../reducers'
import { createUser } from '../../actions'
import { Button, Card, Input, Space } from 'antd'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import Layout from '../../layout'
import UserInformation from '../../containers/UserInformation'
import styles from '../../styles'


class UpdateUser extends React.Component {

    state = { password: '' }

    handleGenerate = () => {
        let text = ''
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$#"
        for (var i = 0; i < 7; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length))
        this.setState({ password: text })
    }

    handlePasswordChange = (e) => this.setState({ password: e.currentTarget.value })

    handlePasswordUpdate = () => {
        const { password } = this.state
        const { user } = this.props
        this.props.createUser({ nCodPers: user.nCodPers, password })
    }

    render() {
        const { user } = this.props
        const { password } = this.state

        return <Layout type={"backpage"} >
            <Card style={styles.generalCard}>
                <UserInformation user={user} />
                <Space>
                    <Input.Password
                        value={password}
                        onChange={this.handlePasswordChange}
                        placeholder="Contraseña"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    <Button onClick={this.handleGenerate} type={"ghost"}>Generar</Button>
                    <Button type={"primary"} block onClick={this.handlePasswordUpdate}>Guardar</Button>
                </Space>

            </Card>
        </Layout>
    }

}

const mapStateToProps = (state, ownProps) => ({
    user: getUser(state, ownProps.match.params.id)
})

const mapDispatchToProps = { createUser }

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser)
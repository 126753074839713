import React from 'react'
import { connect } from 'react-redux'
import { saveElectroPrevalue } from '../../actions'
import Layout from '../../layout'
import Delivery from '../../containers/Delivery'
import Electro from '../../containers/Electro'
import AddressForm from '../../containers/AddressForm'
import AgencySelection from '../../containers/AgencySelection'
import EmailSend from '../../containers/EmailSend'
import ConfirmationBlock from '../../components/ConfirmationBlock'
import NoLoginForm from '../../containers/NoLoginForm'

class PrevalueElectroDom extends React.Component {

    state = {
        page: 1,
        family: 0,
        familyName: '',
        product: 0,
        productName: '',
        brand: 0,
        brandName: '',
        line: 0,
        lineName: '',
        model: 0,
        modelName: '',
        image1: [],
        image2: [],
        image3: [],
        image4: [],
        delivery: 0,
        agency: 0,
        address: '',
        addressRef: '',
        departamento: '',
        municipio: '',
        phone: '',
        email: '',
        name: '',
        contactFrom: 2
    }

    setElectro = (entities) => {
        this.setState(entities, () => { this.setState({ page: 3 }) })
    }

    setInitialForm = (values) => {
        this.setState(values, () => { this.setState({ page: 7 }) }) 
    }

    setUpdateState = (object) => {
        this.setState(object)
    }

    setDelivery = (value) => {
        this.setState({ delivery: value }, () => {
            if (value === 1) {
                this.setState({ page: 3 })
            } else {
                this.setState({ page: 4 })
            }
        })
    }

    setAgency = (value) => {
        this.setState({ agency: value }, () => {
            this.setState({ page: 5 })
        })
    }

    setAddress = (values) => {
        this.setState(values, () => {
            this.setState({ page: 5 })
        })
    }

    setEmail = (values) => {
        this.setState(values, () => {
            this.saveForm()
        })
    }


    setMessage = () => {
        return `Monto prevalúo podría aumentar o disminuir en base a revisión técnica, física e historial interno, Su prevalúo ha sido enviado exitosamente.`
    }

    saveForm = () => {
        const { saveElectroPrevalue } = this.props

        const formData = new FormData()
        this.state.image1.forEach(file => {
            formData.append('image1', file.originFileObj)
        })
        this.state.image2.forEach(file => {
            formData.append('image2', file.originFileObj)
        })
        this.state.image3.forEach(file => {
            formData.append('image3', file.originFileObj)
        })
        if (typeof this.state.image4 !== 'undefined') {
            this.state.image4.forEach(file => {
                formData.append('image4', file.originFileObj)
            })
        }
        formData.append('family', this.state.familyName)
        formData.append('product', this.state.productName)
        formData.append('brand', this.state.brandName)
        formData.append('line', this.state.lineName)
        formData.append('model', this.state.modelName)
        formData.append('nCodModelo', this.state.model)
        formData.append('delivery', this.state.delivery)
        formData.append('agency', this.state.agency)
        formData.append('address', this.state.address)
        formData.append('addressRef', this.state.addressRef)
        formData.append('departamento', this.state.departamento)
        formData.append('municipio', this.state.municipio)
        formData.append('contactFrom', this.state.contactFrom)
        formData.append('nCodPers', 0)
        formData.append('name', this.state.name)
        formData.append('phone', this.state.phone)
        formData.append('email', this.state.email)
        saveElectroPrevalue(formData)
        this.setState({ page: 6 })
    }

    cancelDelivery = () => this.setState({ page: 0, delivery: 0 })
    cancelAgency = () => this.setState({ page: 0, agency: 0 })
    cancelEmail = () => this.setState({ page: 3 })
    cancelAddress = () => this.setState({ page: 0, address: '', phone: '', addressRef: '', departamento: '', municipio: '' })


    render() {
        const { page } = this.state
        switch (page) {
            case 1:
                return <Layout type={"NoLogin"} >
                    <NoLoginForm setInitialForm={this.setInitialForm} />
                </Layout>
            case 2:
                return <Layout type={"NoLogin"} >
                    <Delivery setDelivery={this.setDelivery} cancelDelivery={this.cancelDelivery} />
                </Layout>
            case 3:
                return <Layout type={"NoLogin"} >
                    <AgencySelection cancelAgency={this.cancelAgency} setAgency={this.setAgency} />
                </Layout>
            case 4:
                return <Layout type={"NoLogin"} >
                    <AddressForm cancelAddress={this.cancelAddress} setAddress={this.setAddress}  defaultValues={this.state}/>
                </Layout>
            case 5:
                return <Layout type={"NoLogin"} >
                    <EmailSend cancelEmail={this.cancelEmail} setEmail={this.setEmail} />
                </Layout>
            case 6:
                return <Layout type={"NoLogin"} >
                    <ConfirmationBlock message={this.setMessage()} />
                </Layout>
            default:
                return <Layout type={"frontpage"} >
                    <Electro setElectro={this.setElectro} setUpdateState={this.setUpdateState} />
                </Layout>
        }
    }
}


const mapDispatchToProps = { saveElectroPrevalue }

export default connect(undefined, mapDispatchToProps)(PrevalueElectroDom)
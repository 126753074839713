import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUser, getAuth, getContracts } from '../../reducers'
import { fetchContracts } from '../../actions'
import {
    Card, Typography, Table, Button
} from 'antd'
import CardHeaderInformation from '../../components/CardHeaderInformation'
import Layout from '../../layout'
import styles from '../../styles'

const { Column } = Table

const tableContracts = (contracts) => {
    return contracts.map(contract => {
        return {
            key: contract.contrato,
            ncontract: contract.numerocontrato,
            agency: contract.agency,
            pres: contract.pres,
            sal: contract.sal,
            state: contract.estado,
            totalPagar: contract.TotalPagar
        }
    })
}


class ContractList extends React.Component {

    componentDidMount() {
        this.props.fetchContracts(this.props.auth.cDNI)
    }


    render() {
        const { auth, contracts } = this.props
        return (
            <Layout type={"frontpage"}>
                <Card bordered={false}
                    title={<Typography.Title level={2} className={"text-center"} style={styles.whiteText}>MIS CONTRATOS</Typography.Title>}
                    headStyle={styles.cover}
                    bodyStyle={styles.cardNoPadding}
                    style={styles.generalCard}
                >
                    <CardHeaderInformation
                        name={auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat}
                        dni={auth.cDNI}
                    />
                    <Table dataSource={tableContracts(contracts)} size={"small"}>
                        <Column title={"Contrato"} className={"text-center"} render={(record) =>
                            <Link to={`/account/contracts/detail/${record.key}`}>
                                <Button type={"primary"} size={"small"} >{record.ncontract}</Button ><br/>
                                <span style={record.state === "VIGENTE" ? 
                                    styles.textGreen : record.state === "VENCIDO" ? styles.textRed : styles.textBlack 
                                } > {record.state} </span>
                            </Link>
                        } />
                        <Column title={"Préstamo"} render={(record) => `$${record.pres}`} />
                        <Column title={"Saldo"} render={(record) => `$${record.sal}`} />
                        <Column title={"Renovación del mes"} render={(record) => `$${record.totalPagar}`} />
                    </Table>
                </Card>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: getUser(state, getAuth(state)),
    contracts: getContracts(state)
})

const mapDispatchToProps = { fetchContracts }

export default connect(mapStateToProps, mapDispatchToProps)(ContractList)
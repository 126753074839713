export const LOG_IN = "LOG_IN"
export const LOG_OUT = "LOG_OUT"
export const CONFIGURATION = "CONFIGURATION"
export const FETCH_AGENCIES = "FETCH_AGENCIES"
export const FETCH_ADMINS = "FETCH_ADMINS"
export const FETCH_ADMIN = "FETCH_ADMIN"
export const FETCH_USERS = "FETCH_USERS"
export const FETCH_USER = "FETCH_USER"
export const FETCH_CONTRACTS = "FETCH_CONTRACTS"
export const FETCH_CONTRACT = "FETCH_CONTRACT"
export const FETCH_INVOICES = "FETCH_INVOICES"
export const FETCH_INVOICE = "FETCH_INVOICE"
export const FETCH_VOUCHER = "FETCH_VOUCHER"
export const FETCH_PROMOTIONS = "FETCH_PROMOTIONS"
export const FETCH_GOODIES = "FETCH_GOODIES"
export const FETCH_ENTITIES = "FETCH_ENTITIES"
export const FETCH_PREVALUES = "FETCH_PREVALUES"
export const FETCH_PREVALUE = "FETCH_PREVALUE"
export const REDIRECT = "REDIRECT"
export const LOADING = "LOADING"

export const APIpath = 'https://api.elsalvador.misempeños.com'
//export const APIpath = 'http://127.0.0.1:3307'

export * from './auth'
export * from './admin'
export * from './agency'
export * from './config'
export * from './user'
export * from './contract'
export * from './invoice'
export * from './promotion'
export * from './entities'
export * from './prevalue'
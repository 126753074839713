import React from 'react'
import { connect } from 'react-redux'
import { Card, Button, Space, Image, Descriptions } from 'antd'
import { getPrevalue } from '../../reducers'
import { APIpath, changeStatus } from '../../actions'
import Layout from '../../layout'
import styles from '../../styles'
import { WhatsAppOutlined } from '@ant-design/icons'

const PrevalueElectro = (props) => {

    const { prevalue } = props
    let images = []
    for (var i = 0; i < prevalue.img; i++) {
        images.push(i + 1)
    }
    return (
        <Layout type={"backpage"} >
            <Card style={styles.generalCard}
                title={"Prevaluo " + prevalue.id + " de " + prevalue.name}
                extra={
                    <Button
                        href={
                            prevalue.delivery !== 2 ?
                                    `https://wa.me/+503${prevalue.phone.replace('-', '')}?text=Estimado%20cliente%20le%20saludamos%20de%20PRESTO,%20su%20prevalúo%20por%20su%20${prevalue.product}%20es%20de%20$${prevalue.amount},%20Monto%20prevalúo%20podría%20aumentar%20o%20disminuir%20en%20base%20a%20revisión%20técnica,%20física%20e%20historial%20interno.`
                                    :
                                    `https://wa.me/+503${prevalue.phone.replace('-', '')}?text=Estimado%20cliente%20le%20saludamos%20de%20PRESTO,%20su%20prevalúo%20por%20su%20${prevalue.product}%20es%20de%20$${prevalue.amount},%20monto%20podrá%20incrementar,%20de%20acuerdo%20a%20valúo%20físico%20de%20su%20artículo,%20un%20ejecutivo%20se%20comunicará%20con%20usted%20para%20programar%20cita%20de%20su%20empeño%20a%20domicilio,%20sujeto%20a%20zonas%20de%20cobertura.%20Presto%20donde%20paga%20menos%20y%20le%20damos%20más.`
                        }
                        icon={<WhatsAppOutlined />}
                        target={"_blank"}
                        rel="noreferrer"
                    > Enviar prevaluo</Button>}
            >
                <Descriptions title={"Información del solicitante"} bordered column={1} >
                    <Descriptions.Item label={"Fecha de solicitud"} >
                        { new Date(prevalue.created_at).toLocaleDateString('es') }
                    </Descriptions.Item>
                    <Descriptions.Item label={"Tipo de cliente"}>
                        {Number(prevalue.nCodPers) !== 0 ? 'Cliente' : 'No cliente'}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Nombre"}>{prevalue.name}</Descriptions.Item>
                    <Descriptions.Item label={"Teléfono"}>{prevalue.phone}</Descriptions.Item>
                    <Descriptions.Item label={"Correo"}>{prevalue.email}</Descriptions.Item>
                    {(Number(prevalue.delivery) === 2 || Number(prevalue.nCodPers) === 0) &&
                        <React.Fragment>
                            <Descriptions.Item label={"Dirección"}>{prevalue.address}</Descriptions.Item>
                            <Descriptions.Item label={"Referencia"}>{prevalue.addressRef}</Descriptions.Item>
                            <Descriptions.Item label={"Departamento"}>{prevalue.departamento}</Descriptions.Item>
                            <Descriptions.Item label={"Municipio"}>{prevalue.municipio}</Descriptions.Item>
                        </React.Fragment>
                    }
                </Descriptions>
                <Descriptions bordered title={"Datos del prevalúo"} column={2}>
                    <Descriptions.Item label={"Tipo de producto"}> {prevalue.family} </Descriptions.Item>
                    <Descriptions.Item label={"Tipo de producto"}> {prevalue.product} </Descriptions.Item>
                    <Descriptions.Item label={"Marca"}> {prevalue.brand} </Descriptions.Item>
                    <Descriptions.Item label={"Linea"}> {prevalue.line} </Descriptions.Item>
                    <Descriptions.Item label={"Modelo"}> {prevalue.model} </Descriptions.Item>
                    <Descriptions.Item label={"Tipo de petición"}> {Number(prevalue.delivery) !== 2 ? 'Agencia' : 'A domicilio'} </Descriptions.Item>
                    <Descriptions.Item label={"Valor de prevalúo"}> ${prevalue.amount} </Descriptions.Item>
                    {Number(prevalue.delivery) !== 2 &&
                        <Descriptions.Item label={"Agencia"}>{prevalue.agencia}</Descriptions.Item>
                    }
                </Descriptions>
                <br />
                <Space wrap={true} align={"center"}>
                    {images.map(i =>
                        <Image key={i} src={`${APIpath}/prevalue/image/${prevalue.id}/${i}`} width={200} height={200} />
                    )}
                </Space>
                {prevalue.status ? undefined : <Button onClick={() => { props.changeStatus(prevalue.id) }} >Finalizar prevalúo</Button>}
            </Card>
        </Layout>
    )
}

const mapStateToProps = (state, ownProps) => ({
    prevalue: getPrevalue(state, ownProps.match.params.id)
})

const mapDispatchToProps = { changeStatus }

export default connect(mapStateToProps, mapDispatchToProps)(PrevalueElectro)
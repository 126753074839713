import React from 'react'
import {
    Card, Select, Form, Button, Upload, Typography, Input
} from 'antd'
import background1 from '../multimedia/background1.svg'
import { UploadOutlined } from '@ant-design/icons'
import styles from '../styles'

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
}

class Moto extends React.Component {

    handleSubmit = (values) => {
        const { setMoto } = this.props
        setMoto(values)
    }

    render() {
        const { setUpdateState } = this.props
        return <Card
            style={styles.generalCard}
            bordered={false}
            cover={<img src={background1} alt={"Fondo"} />}
        >
            <Typography.Title align={"center"}>Prevaluós</Typography.Title>
            <Form
                name={"Moto"}
                onFinish={this.handleSubmit}
                layout={"vertical"}
            >
                <Form.Item label={"Tarjeta circulación"} name={"tarjeta"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Marca"} name={"marca"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Año"} name={"anno"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select>
                        <Select.Option value={"2022"} key={1} >2022</Select.Option>
                        <Select.Option value={"2023"} key={2} >2023</Select.Option>
                        <Select.Option value={"2024"} key={3} >2024</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={"Modelo"} name={"modelo"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Kilometraje"} name={"kilometraje"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"N° de contacto"} name={"nContacto"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input type={"tel"} pattern={"[0-9]{8}"} placeholder={"########"} />
                </Form.Item>
                <Form.Item name="image1" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image1: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image1: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto delantera</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image2" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image2: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image2: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto lateral</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image3" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image3: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image3: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto trasera</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image4" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image4: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image4: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto de tarjeta de circulación</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image5" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image5: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image5: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto de DUI</Button>
                    </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type={"primary"} htmlType="submit" block>Continuar</Button>
                </Form.Item>
            </Form>
        </Card>
    }
}


export default Moto
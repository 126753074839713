import React from 'react'
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer'
import logo from '../multimedia/logoGray.png'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
        backgroundColor: '#FFF'
    },
    section: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: '3px'
    },
    section2: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    section3: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: '3px',
        marginTop: '100px'
    },
    section4: {
        flexDirection: 'column',
        justifyContent: 'center'
    },
    title: {
        fontSize: 8,
        textAlign: 'center',
        fontWeight: 'black',
        textTransform: 'uppercase'
    },
    text: {
        fontSize: 7,
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    textNormalCenter: {
        fontSize: 7,
        textAlign: 'center',
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    textNormalRight: {
        fontSize: 7,
        textAlign: 'right',
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    textRed: {
        fontSize: 7,
        textAlign: 'center',
        color: 'red',
        textTransform: 'uppercase'
    },
    textMini: {
        fontSize: 4,
        textAlign: 'left',
        textTransform: 'uppercase'
    },
    boxCompany: {
        width: '40%',
        justifyContent: 'center'
    },
    boxLogo: {
        width: '25%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    boxInfo: {
        border: 2,
        width: '20%',
        justifyContent: 'center'
    },
    boxClient: {
        border: 1,
        width: '95%',
        margin: 'auto',
        flexDirection: 'row'
    },
    textMark : {
        color: '#989898',
        fontSize: 24,
        fontWeight: 'bold',
    },
    markBox : {
        top: 275,
        left: 100,
        position: 'absolute',
        zIndex: '1000',
        transform: 'rotate(-25deg)'
    }
})

const MyDocument = ({ voucher }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section3}>
                <View style={styles.boxLogo}>
                    <Image src={logo} style={{ width: '125px', height: '47px' }} />
                    <Text style={styles.textMini}>NOTA: RENUEVA TU CONTRATO EN 30 DÍAS Y EVITA INCONVENIENTES</Text>
                </View>
                <View style={styles.boxCompany}>
                    <Text style={styles.title}>{voucher.cEmpresa}</Text>
                    <Text style={styles.title}>{voucher.cAgencia}</Text>
                    <Text style={styles.textNormalCenter}>{voucher.cDirAgen}</Text>
                    <Text style={styles.textNormalCenter}> VENTA AL POR MENOR DE OTROS PRODUCTOS N.C.P</Text>
                    <Text style={styles.textNormalCenter}>Tel.{voucher.cTelAgen}</Text>
                </View>
                <View style={styles.boxInfo}>
                    <Text style={styles.textRed}>FACTURA</Text>
                    <Text style={styles.textNormalCenter}>No. {voucher.cSerie}</Text>
                    <Text style={styles.textRed}>{voucher.nNumero}</Text>
                    <Text style={styles.textNormalCenter}>NIT: {voucher.cNIT}</Text>
                    <Text style={styles.textNormalCenter}>NRC: {voucher.cNRC}</Text>
                </View>
            </View>
            <View style={styles.section}>
                <View style={styles.boxClient} >
                    <View style={{ width: '70%', justifyContent: 'flex-start', padding: '5px' }}>
                        <Text style={styles.text}>CLIENTE: {voucher.cCliente}</Text>
                        <Text style={styles.text}>DIRECCION: {voucher.cDireccion}</Text>
                        <Text style={styles.text}>nit: {voucher.cNITC}  VTA A CTA DE: </Text>
                    </View>
                    <View style={{ width: '30%', justifyContent: 'flex-start', padding: '5px' }}>
                        <Text style={styles.text}>fecha: {new Date(voucher.FechaOperacion).toLocaleDateString('es')}</Text>
                        <Text style={styles.text}>condiciones: CONTADO</Text>
                        <Text style={styles.text}>dui: {voucher.cDocumento}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.section2}>
                <View style={styles.boxClient} >
                    <View style={{ borderRight: 1, width: '10%' }}>
                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.textNormalCenter}>Cantidad</Text>
                            <Text style={styles.text}>&nbsp;</Text>
                        </View>
                        <View>
                            <Text style={styles.text}>&nbsp;</Text>
                        </View>
                    </View>
                    <View style={{ borderRight: 1, width: '10%' }}>
                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.textNormalCenter}>Código</Text>
                            <Text style={styles.text}>&nbsp;</Text>
                        </View>
                        <View>
                            <Text style={styles.text}>&nbsp;</Text>
                        </View>
                    </View>
                    <View style={{ borderRight: 1, width: '40%' }}>
                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.textNormalCenter}>Descripción</Text>
                            <Text style={styles.text}>&nbsp;</Text>
                        </View>
                        <View>
                            <Text style={styles.text}>{voucher.TipoOperacion}: ${voucher.nIntSinIGV} D.T.: {voucher.nDiasTranscurridosAlPago}</Text>
                            <Text style={styles.text}>Factura: {voucher.cDocumento}</Text>
                            <Text style={styles.text}>N° Contrato: {voucher.cNroContrato}</Text>
                            <Text style={styles.title}>Información próximo pago:</Text>
                            <Text style={styles.text}>Fecha de pago: {voucher.dFechaVencimiento}</Text>
                            <Text style={styles.text}>Saldo: ${voucher.nNuevoSaldo}</Text>
                            <Text style={styles.text}>Comisión: ${voucher.nIGV}   D.P.:{voucher.nDiasPendiente}</Text>
                        </View>
                    </View>
                    <View style={{ borderRight: 1, width: '10%' }}>
                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.textNormalCenter}>Precio</Text>
                            <Text style={styles.textNormalCenter}>Unitario</Text>
                        </View>
                        <View>
                            <Text style={styles.textNormalRight}>&nbsp;</Text>
                        </View>
                    </View>
                    <View style={{ borderRight: 1, width: '10%' }}>

                        <View style={{ borderBottom: 1 }} >
                            <Text style={styles.textNormalCenter}>Ventas</Text>
                            <Text style={styles.textNormalCenter}>No sujetas</Text>
                        </View>

                        <View>
                            <Text style={styles.textNormalRight}>${voucher.nCapitalPagado}</Text>
                        </View>
                    </View>
                    <View style={{ borderRight: 1, width: '10%' }}>
                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.text}>Ventas</Text>
                            <Text style={styles.text}>Exentas</Text>
                        </View>
                        <View>
                            <Text style={styles.textNormalRight}></Text>
                        </View>
                    </View>
                    <View style={{ border: 0, width: '10%' }}>

                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.text}>Ventas</Text>
                            <Text style={styles.text}>Afectas</Text>
                        </View>
                        <View>
                            <Text style={styles.textNormalRight}> ${voucher.nInteres + voucher.nDescuento}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.section2}>
                <View style={styles.boxClient}>
                    <View style={{ borderRight: 1, width: '60%' }}>
                        <Text style={styles.text}>{voucher.NumeroEnLetra}</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '10%' }}>
                        <Text style={styles.text}>SUMAS</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '10%' }}>
                        <Text style={styles.textNormalRight}>${voucher.nCapitalPagado}</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '10%' }}>
                        <Text style={styles.textNormalRight}></Text>
                    </View>
                    <View style={{ width: '10%' }}>
                        <Text style={styles.textNormalRight}>${voucher.nInteres + voucher.nDescuento}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.section2}>
                <View style={styles.boxClient}>
                    <View style={{ borderRight: 1, width: '30%' }}>
                        <Text style={styles.text}>Entregado por: {voucher.cUsuario}</Text>
                        <Text style={styles.text}>NIT/DUI</Text>
                        <Text style={styles.text}>Firma:</Text>
                        <Text style={styles.text}></Text>
                    </View>
                    <View style={{ borderRight: 1, width: '30%' }}>
                        <Text style={styles.text}>Recibido por:</Text>
                        <Text style={styles.text}>NIT/DUI {voucher.cDocumento}</Text>
                        <Text style={styles.text}>Firma: </Text>
                        <Text style={styles.text}></Text>
                    </View>
                    <View style={{ borderRight: 1, width: '20%' }}>
                        <Text style={styles.text}>Ventas no sujetas</Text>
                        <Text style={styles.text}>Ventas Exentas</Text>
                        <Text style={styles.text}>Subtotal</Text>
                        <Text style={styles.text}>Total</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '10%' }}>
                        <Text style={styles.text}>&nbsp;</Text>
                    </View>
                    <View style={{ width: '10%' }}>
                        <Text style={styles.textNormalRight}>${voucher.nInteres + voucher.nDescuento}</Text>
                        <Text style={styles.textNormalRight}>&nbsp;</Text>
                        <Text style={styles.textNormalRight}>&nbsp;</Text>
                        <Text style={styles.textNormalRight}>${voucher.MontoTotalOperado}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.section4}>
                <Text style={styles.textRed}>Copia cliente</Text>
            </View>
            <View style={styles.markBox}>
                <Text style={styles.textMark}>FACTURA ENTREGADA A CLIENTE</Text>
            </View>
        </Page>
    </Document>
);

export default MyDocument
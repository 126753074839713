import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getUsers, getRedirect } from '../../reducers'
import { searchUser, createUser } from '../../actions'
import { Button, Card, Select, Input, Space } from 'antd'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import Layout from '../../layout'
import UserInformation from '../../containers/UserInformation'
import styles from '../../styles'
import { debounce } from '../../utils'

const { Option } = Select

class CreateUser extends React.Component {

    state = {
        nCodPers: '',
        password: '',
    }

    handleGenerate = () => {
        let text = ''
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$#"
        for (var i = 0; i < 7; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length))
        this.setState({ password: text })
    }

    handlePasswordChange = (e) => this.setState({ password: e.currentTarget.value })

    handleSelectChange = value => this.setState({ nCodPers: value })

    fetchUser = (value) => {
        debounce(this.props.searchUser(value), 800)
    }

    handleSubmit = () => {
        const { password, nCodPers } = this.state
        this.props.createUser({ nCodPers, password })
    }

    render() {
        const { users, redirect } = this.props
        const { password, nCodPers } = this.state

        if (redirect) {
            return <Redirect to="/admin/user/list" />
        }

        return <Layout type={"backpage"} >
            <Card style={styles.generalCard}>

                <Select
                    value={nCodPers}
                    filterOption={false}
                    showSearch={true}
                    onSearch={this.fetchUser}
                    onChange={this.handleSelectChange}
                    allowClear
                    style={{ width: '100%' }}
                >
                    {users.map(d => (
                        <Option key={d.nCodPers} value={d.nCodPers}>{d.cDNI}</Option>
                    ))}
                </Select>
                <br /><br />
                {users.reduce((render, user,) => {
                    if (nCodPers === user.nCodPers)
                        return <UserInformation user={user} />
                    return render
                }, undefined)
                }

                <Space>
                    <Input.Password
                        value={password}
                        onChange={this.handlePasswordChange}
                        placeholder="Contraseña"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    <Button onClick={this.handleGenerate} type={"ghost"}>Generar</Button>
                </Space>
                <br /><br />
                <Button type={"primary"} block onClick={this.handleSubmit}>Habilitar</Button>
            </Card>
        </Layout>
    }

}

const mapStateToProps = (state) => ({
    users: getUsers(state),
    redirect: getRedirect(state)
})

const mapDispatchToProps = { searchUser, createUser }

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)
import React from 'react'
import { Link } from 'react-router-dom'
import {
    Card, Row, Col, Typography
} from 'antd'
import Layout from '../../layout'
import background2 from '../../multimedia/background2.svg'
import icon7 from '../../multimedia/icon7.svg'
import icon6 from '../../multimedia/icon6.svg'
import styles from '../../styles'

class PrevalueVehicleList extends React.Component {

    render() {
        return <Layout type={"NoLogin"}>
            <Card
                cover={<img src={background2} alt={"Fondo"} />}
                bordered={false}
            >
                <Card className={'text-center'} bordered={false}>
                    <Row>
                        <Col span={24}>
                            <Link to={"/prevaluos/moto"}>
                                <img src={icon7} alt={"MOTO"} style={styles.iconMenu} />
                                <Typography.Title level={5} >MOTO</Typography.Title>
                            </Link>
                        </Col>
                        <Col span={24}>
                            <Link to={"/prevaluos/car"}>
                                <img src={icon6} alt={"CARRO"} style={styles.iconMenu} />
                                <Typography.Title level={5} >CARRO</Typography.Title>
                            </Link>
                        </Col>
                    </Row>
                </Card>
            </Card>
        </Layout>
    }
}

export default PrevalueVehicleList
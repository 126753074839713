import { message } from 'antd'
import axios from 'axios'
import {
    FETCH_USER,
    FETCH_USERS,
    FETCH_GOODIES,
    APIpath,
    setRedirect
} from '../actions'

export const setUsers = (users) => ({
    type: FETCH_USERS,
    users
})

export const setUser = (user) => ({
    type: FETCH_USER,
    user
})

const setGoodies = (goodies) => ({
    type: FETCH_GOODIES,
    goodies
})



export const fetchUsers = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/user/`)
        .then(function (response) {
            dispatch(setUsers(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const searchUser = (value) => (dispatch) => {
    axios.post(`${APIpath}/user/search`, { dni: value })
        .then(function (response) {
            dispatch(setUsers(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const createUser = (values) => (dispatch) => {

    axios.post(`${APIpath}/user/store`, values)
        .then(function (response) {
            if (response.data > 0) {
                message.success("Usuario habilitado para uso de aplicación")
                dispatch(setRedirect(true))
            } else {
                message.error("Ha ocurrido un error")
            }
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}


export const updateUser = (values) => (dispatch) => {
    axios.post(`${APIpath}/user/update`, values)
        .then(function (response) {
            axios.get(`${APIpath}/user/search/${values.nCodPers}`)
                .then(function (response) {
                    dispatch(setUser(response.data))
                    message.success("Usuario actualizado")
                })
                .catch(function (err) {
                    console.log(err)
                    message.error("Ha ocurrido un error")
                })
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const changeUserPassword = (values) => dispatch => {
    axios.post(`${APIpath}/user/password`, values)
        .then(function (response) {
            if(response.data[0] > 0){
                message.success("Ha realizado el cambio de contraseña")
            }else {
                message.error("La contraseña actual no coincide con nuestros registros")
            }
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const fetchGoodies = (nCodPers) => dispatch => {
    axios.get(`${APIpath}/user/goodies/${nCodPers}`)
        .then(function (response) {
            dispatch(setGoodies(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}
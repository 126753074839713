import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from "react-router-dom"
import { adminLogin } from '../../actions'
import { getAuth } from '../../reducers'
import { Button, Card, Input, Form } from 'antd'
import Layout from '../../layout'
import styles from '../../styles'


class Login extends React.Component {

    storeLogin = (values) => {
        this.props.adminLogin(values)
    }


    render() {
        const { location, isAuth } = this.props
        if (isAuth) {
            let { from } = location.state || { from: { pathname: '/admin/' } }
            return <Redirect to={from} />
        }

        return <Layout>
            <Card style={styles.cardAuthpage}>
                <Form
                    name='login'
                    onFinish={this.storeLogin}
                    layout="vertical"
                    style={{minWidth: "250px", textAlign: "center"}}
                >

                    <Form.Item label="Correo" name="email" placeholder={"Correo"} rules={[{ required: true, message: 'Correo es requerido' }]}>
                        <Input type='email' />
                    </Form.Item>

                    <Form.Item label="Contraseña" name="password" placeholder={"Contraseña"} rules={[{ required: true, message: 'Contraseña es requerido' }]} >
                        <Input type='password' />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary"  block htmlType="submit">
                            Ingresar
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Layout >
    }
}

const mapStateToProps = (state) => ({
    isAuth: getAuth(state) === undefined ? false : true
})

const mapDispatchToProps = ({ adminLogin })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
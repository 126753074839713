import React from 'react'
import { connect } from 'react-redux'
import { getUser, getAuth } from '../../reducers'
import { saveVehiclePrevalue } from '../../actions'
import Layout from '../../layout'
import Moto from '../../containers/Moto'
import AddressForm from '../../containers/AddressForm'
import ConfirmationBlock from '../../components/ConfirmationBlock'

class PrevalueMotoDom extends React.Component {

    state = {
        page: 1,
        type: 5,
        tarjeta: 0,
        marca: '',
        anno: 0,
        modelo: 0,
        kilometraje: 0,
        nContacto: 0,
        image1: [],
        image2: [],
        image3: [],
        image4: [],
        image5: [],
        delivery: 0,
        agency: 0,
        address: '',
        addressRef: '',
        departamento: '',
        municipio: '',
        phone: '',
        email: '',
        contactFrom: 2
    }

    setMoto = (entities) => {
        this.setState(entities, () => { this.setState({ page: 4 }) })
    }

    setUpdateState = (object) => {
        this.setState(object)
    }

    setAddress = (values) => {
        this.setState(values, () => {
            this.saveForm()
        })
    }

    saveForm = () => {
        const { auth, saveVehiclePrevalue } = this.props

        const formData = new FormData()
        this.state.image1.forEach(file => {
            formData.append('image1', file.originFileObj)
        })
        this.state.image2.forEach(file => {
            formData.append('image2', file.originFileObj)
        })
        this.state.image3.forEach(file => {
            formData.append('image3', file.originFileObj)
        })
        this.state.image4.forEach(file => {
            formData.append('image4', file.originFileObj)
        })
        this.state.image5.forEach(file => {
            formData.append('image5', file.originFileObj)
        })
        formData.append('type', this.state.type)
        formData.append('marca', this.state.marca)
        formData.append('tarjeta', this.state.tarjeta)
        formData.append('anno', this.state.anno)
        formData.append('modelo', this.state.modelo)
        formData.append('kilometraje', this.state.kilometraje)
        formData.append('nContacto', this.state.nContacto)
        formData.append('delivery', this.state.delivery)
        formData.append('agency', this.state.agency)
        formData.append('address', this.state.address)
        formData.append('addressRef', this.state.addressRef)
        formData.append('departamento', this.state.departamento)
        formData.append('municipio', this.state.municipio)
        formData.append('contactFrom', this.state.contactFrom)
        formData.append('nCodPers', auth.nCodPers)
        formData.append('name', auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat)
        formData.append('phone', this.state.phone === '' ? auth.cMovil : this.state.phone)
        formData.append('email', this.state.email === '' || this.state.email === undefined ? auth.cMail : this.state.email)
        saveVehiclePrevalue(formData)
        this.setState({ page: 5 })
    }

    cancelAddress = () => this.setState({ page: 2, address: '', phone: '', addressRef: '', departamento: '', municipio: '' })

    render() {
        const { page } = this.state
        switch (page) {
            case 4:
                return <Layout type={"frontpage"} >
                    <AddressForm cancelAddress={this.cancelAddress} setAddress={this.setAddress} />
                </Layout>
            case 5:
                return <Layout type={"frontpage"} >
                    <ConfirmationBlock
                        message={`Estimado cliente: su solicitud ha sido enviada exitosamente, un ejecutivo se comunicará contigo para brindarle mayor información del proceso de su pre-solicitud. Presto donde tu moto vale más`}
                    />
                </Layout>
            default:
                return <Layout type={"frontpage"} >
                    <Moto setMoto={this.setMoto} setUpdateState={this.setUpdateState} />
                </Layout>
        }
    }
}

const mapStateToProps = (state) => ({
    auth: getUser(state, getAuth(state))
})

const mapDispatchToProps = { saveVehiclePrevalue }

export default connect(mapStateToProps, mapDispatchToProps)(PrevalueMotoDom)